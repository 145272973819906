@import url("//hello.myfonts.net/count/37ae06");

@font-face {
	font-family: 'Praktika-BlackExtended';
	src: url("../fonts/37AE06_0_0.eot");
	src: url("../fonts/37AE06_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/37AE06_0_0.woff2") format("woff2"), url("../fonts/37AE06_0_0.woff") format("woff"), url("../fonts/37AE06_0_0.ttf") format("truetype"); }

@font-face {
	font-family: 'Praktika-BoldExtended';
	src: url("../fonts/37AE06_1_0.eot");
	src: url("../fonts/37AE06_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/37AE06_1_0.woff2") format("woff2"), url("../fonts/37AE06_1_0.woff") format("woff"), url("../fonts/37AE06_1_0.ttf") format("truetype"); }

@font-face {
	font-family: 'Praktika-ExtraBoldExtended';
	src: url("../fonts/37AE06_2_0.eot");
	src: url("../fonts/37AE06_2_0.eot?#iefix") format("embedded-opentype"), url("../fonts/37AE06_2_0.woff2") format("woff2"), url("../fonts/37AE06_2_0.woff") format("woff"), url("../fonts/37AE06_2_0.ttf") format("truetype"); }
